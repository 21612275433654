 .fade-in {
    opacity: 1;
    transition: opacity linear 1.5s;
  }
  .fade-out {
    opacity: 0;
    transition: opacity linear 1.5s;
  }
  .background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  
  