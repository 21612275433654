html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom: 170px;
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 170px;
}

.grid-container {
  height: calc(500px);
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px; 
  margin-right: 20px; 
  padding: 20px;
  display: flex;
}

.grid-column {
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
